<template>
	<div class="course-wrapper">
		<div class="course-wrapper-title">
			<span>员工管理</span>
		</div>
		<div class="course-wrapper-body">
			<!-- 功能按钮 -->
			<div class="course-wrapper-function">
				<div class="function-left">
					<el-input class="input-search" placeholder="姓名/账号/手机" clearabel></el-input>

					<el-select placeholder="选择角色" value="" class="input-select">
						<el-option label="1" value="选项1"></el-option>
						<el-option label="2" value="选项2"></el-option>
					</el-select>

					<el-select placeholder="状态选项" value="" class="input-select">
						<el-option label="1" value="选项1"></el-option>
						<el-option label="2" value="选项2"></el-option>
					</el-select>

					<el-date-picker type="daterange" unlink-panels range-separator="~"></el-date-picker>

					<el-button type="primary" icon="el-icon-search">搜索</el-button>

					<el-button icon="el-icon-refresh-left">重置</el-button>
				</div>

				<div class="function-left">
					<el-button @click="handleemployeeButton(1)">修改部门</el-button>

					<el-button @click="handleemployeeButton(2)">修改角色</el-button>

					<el-button @click="handleemployeeButton(3)">设置状态</el-button>

					<el-button @click="handleemployeeButton(4)">重置密码</el-button>
				</div>

				<div class="function-right">
					<el-button
						@click="handleEmployeeButton()"
						icon="el-icon-refresh" size="">同步</el-button>

					<el-button
						@click="handleEmployeeButtonInsert()"
						type="primary" icon="el-icon-plus">新建员工</el-button>
				</div>
			</div>

			<el-table :data="employeeTableData" border>
				<el-table-column type="selection"></el-table-column>

				<el-table-column prop="" label="姓名"></el-table-column>

				<el-table-column prop="" label="部门"></el-table-column>

				<el-table-column prop="" label="角色"></el-table-column>

				<el-table-column prop="" label="账号状态"></el-table-column>

				<el-table-column prop="" label="手机"></el-table-column>

				<el-table-column prop="" label="最后登录"></el-table-column>

				<el-table-column prop="" label="操作" class-name="table-handle" align="center" width="200" fixed="right">
					<template>
						<el-button type="text">编辑</el-button>
						<el-button type="text" class="button-danger">删除</el-button>
						<el-button @click="tableHandleDetails" type="text">详情</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div class="course-wrapper-footer">
			<div class="footer-left">
				<div class="table-select">
					<el-button>全选</el-button>
					<el-button>反选</el-button>
				</div>
			</div>

			<div class="footer-right">
				<el-pagination layout="prev, pager, next, sizes"></el-pagination>
			</div>
		</div>

		<employee-dialog :employeeHandleData="employeeHandleData"></employee-dialog>
		<employee-details :employeeHandleDetailsData="employeeHandleDetailsData"></employee-details>
	</div>
</template>

<script>
import employeeDialog from './employeeDialog.vue';
import employeeDetails from './employeeDetails.vue';
export default {
	name: 'employeeManage',
	components: {
		employeeDialog,
		employeeDetails,
	},
	data() {
		return {
			employeeTableData: [
				{},
				{},
			],
			employeeHandleData: {},
			employeeHandleDetailsData: {},
		}
	},
	methods: {
		/**
		 * @description:
		 * @param {number} handleName 1-修改部门，2-修改角色，3-设置状态， 4-重置密码
		 * @return {*}
		 */
		handleEmployeeButton(handleName) {
			switch (handleName) {
				case 1:
					this.employeeHandleData = {
						handleName,
						title: '修改部门'
					};
					break;
				case 2:
					this.employeeHandleData = {
						handleName,
						title: '修改角色'
					};
					break;
				case 3:
					this.employeeHandleData = {
						handleName,
						title: '设置状态'
					};
				case 4:
					this.employeeHandleData = {
						handleName,
						title: '重置密码'
					};
				default:
					break;
			}
		},
		tableHandleDetails() {
			this.employeeHandleDetailsData = {};
		},
		handleEmployeeButtonInsert() {
			this.$router.push({ name: 'employeeInsert', meta: { title: '修改员工' } });
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/common_c.scss';
/deep/.course-wrapper-body {
	.course-wrapper-function {
		flex-wrap: wrap;
		.function-left {
			flex-wrap: wrap;
			.input-select,
			.input-search {
				margin-right: 10px;
				margin-bottom: 10px;
				flex-grow: 0;
				width: 220px;

			}
			.el-date-editor--daterange {
				margin-right: 10px;
				width: 250px;
			}
		}
	}

	.el-table-column--selection {
		text-align: center;
		.el-checkbox {
			margin-right: 0;
		}
	}

}
.course-wrapper-footer {
	display: flex;
	justify-content: space-between;
}
</style>
