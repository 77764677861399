<template>
	<el-dialog
		title="员工详情页"
		:visible.sync="dialogVisible"
		:before-close="dialogBeforeClose"
		width="80%">
		<div class="course-wrapper-body">
			<div class="item-title">基本信息<i class="el-icon-arrow-up"></i></div>

			<el-form label-width="5.20833vw" class="employee-details-info">
				<el-form-item label="所属组织：">建业教育</el-form-item>
				<el-form-item label="所属学校：">建业小哈佛</el-form-item>
				<el-form-item label="所在部门：">院办</el-form-item>
				<el-form-item label="角色：">园长</el-form-item>
				<el-form-item label="姓名：">乐乐</el-form-item>
				<el-form-item label="手机号码：">13414414</el-form-item>
				<el-form-item label="证件类型：">身份证</el-form-item>
				<el-form-item label="证件号码：">4121207380239182739</el-form-item>
				<el-form-item label="性别：">男</el-form-item>
				<el-form-item label="个人简介："></el-form-item>
			</el-form>

			<div class="item-title">基本信息<i class="el-icon-arrow-up"></i></div>

			<el-table
				:data="[{}]"
				class="employee-details-classes">
				<el-table-column prop="" label="年纪"></el-table-column>
				<el-table-column prop="" label="班级"></el-table-column>
				<el-table-column prop="" label="班主任"></el-table-column>
				<el-table-column prop="" label="操作" align="center">
					<el-button type="text">取消关联</el-button>
				</el-table-column>
			</el-table>
		</div>


	</el-dialog>
</template>

<script>
export default {
	name: 'employeeInsert',
	props: {
		employeeHandleDetailsData: Object,
	},
	data() {
		return {
			dialogVisible: false,
		}
	},
	methods: {
		dialogBeforeClose() {
			this.dialogVisible = false;
		},
	},
	watch: {
		employeeHandleDetailsData() {
			this.dialogVisible = true;
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/common_c.scss';
/deep/.course-wrapper-body {
	.item-title {
		padding-left: 10px;
		border-left: 8px solid $colorBorder;
		.el-icon-arrow-up {
			float: right;
			color: $colorBorder;
			font-weight: bold;
		}
	}

	.employee-details-info{
		padding: 40px 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.el-form-item {
			width: 300px;
			> label {
				padding-right: 0;
				color: #999;
				font-weight: bold;
			}
		}
	}

	.employee-details-classes {
		padding-top: 40px;
	}
}
</style>
