<template>
<div>
	<el-dialog
	:title="employeeDialog.title"
	:visible.sync="employeeDialog.visible"
	:before-close="employeeDialogClose"
	class="employee-dialog">
		<el-form label-width="5.20833vw">
		<el-form-item label="已选员工：">
			<div class="selected-item">
				<div class="head"></div>
				<div class="name">张小刚</div>
				<div class="info">教研部/教研总监</div>
				<div class="close"></div>
			</div>
		</el-form-item>

		<el-form-item v-if="employeeDialog.handleName === 1" label="选择部门：">
			<el-select placeholder="选择组织" value="" class="input-select">
				<el-option label="组织1" value="选项1"></el-option>
				<el-option label="组织2" value="选项2"></el-option>
			</el-select>
		</el-form-item>

		<el-form-item v-else-if="employeeDialog.handleName === 2" label="选择角色：">
			<el-select placeholder="选择组织" value="" class="input-select">
				<el-option label="组织1" value="选项1"></el-option>
				<el-option label="组织2" value="选项2"></el-option>
			</el-select>
		</el-form-item>

		<el-form-item v-else-if="employeeDialog.handleName === 3">
			新密码将以手机短信发送至员工的手机号码
		</el-form-item>

		<el-form-item v-else-if="employeeDialog.handleName === 4" label="选择状态：">
			<el-radio-button>开启</el-radio-button>
			<el-radio-button>禁用</el-radio-button>
		</el-form-item>
		</el-form>

		<div slot="footer">
			<el-button @click="employeeDialogClose">取消</el-button>
			<el-button type="primary" @click="dialogVisible = false">确定</el-button>
		</div>
	</el-dialog>
</div>
</template>

<script>
export default {
	props: {
		employeeHandleData: Object,
	},
	data() {
		return {
			employeeDialog: {
				title: '',
				visible: false,
				handleName: 1,
				rules: {

				}
			}
		}
	},
	methods: {
		employeeDialogClose() {
			this.employeeDialog.visible = false;
		}
	},
	watch: {
		employeeHandleData(data) {
			this.employeeDialog.visible = true;
			this.employeeDialog.title = data.title;
			this.employeeDialog.handleName = data.handleName;
		}
	}
}
</script>

<style lang="scss" scoped>
.employee-dialog {
	/deep/.el-dialog {
		.el-dialog__body {
			.selected-item {
				padding: 2px 10px;
				display: flex;
				flex-direction: row;
				align-items: center;
				border:1px solid #e4e4e4;
				.head {
					width: 20px;
					height: 20px;
					background: #999;
					border-radius: 50%;
				}
				.name {
					padding: 0 20px;
				}
				.info {
					width: 400px;
					font-size: 12px;
				}
				.close {
					width: 10px;
					height: 10px;
					line-height: 10px;
					text-align: center;
					border-radius: 50%;
					color: #999;
					border: 2px solid #999;
					cursor: pointer;
					&:after {
						content: '-';
					}
				}
			}
			.input-select {
				width: 100%;
			}
		}
	}
}
</style>
